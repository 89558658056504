export default function Configs(that) {

    const searchItems = [
        {
            label: "",
            span: 8,
            placeholder: '请输入桥梁名称',
            model: "name"
        },
        {
            label: "",
            span: 8,
            placeholder: '请选择城市',
            model: "city",
            type:'select',
            options:[],
        },
        {
            label: "",
            span: 8,
            placeholder: '请选择结构类型',
            model: "type",
            type:'select',
            options:[],
        },
    ]
    return {
        searchItems,
    }
}
