<template>
  <div class="platform-bridge-group manage-scroll">
    <div class="search-wrapper">
      <c-search
        v-model="form"
        labelWidth="10px"
        :searchItems="searchItems"
        @reset="refresh"
        @search="pageGet(true)"
      >
      </c-search>
    </div>
    <div class="manage-scroll bridge-wrapper">
      <ul class="bridge-list">
        <li @click="goSingle" v-for="(item, index) in bridgeGroup" :key="index">
          <img src="../../../../assets/images/single/brigde.jpg" alt="" />
          <div class="bridge-info">
            <div class="info-left">{{ item.name }}</div>
            <div class="info-right">
              <div class="mr-6">
                <i class="iconfont icon-chaozai mr-2"></i>
                {{ item.over }}
              </div>
              <div>
                <i class="iconfont icon-bj mr-2"></i>
                {{ item.alert }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Configs from "./config/index";
export default {
  name: "index",
  data() {
    let { searchItems } = Configs(this);
    return {
      searchItems,
      form: {
        name: "",
        city: "",
        type: "",
      },
      bridgeGroup: [
        {
          id: 1,
          name: "长江大桥",
          over: 251,
          alert: 121,
        },
        {
          id: 1,
          name: "长江大桥",
          over: 251,
          alert: 121,
        },
        {
          id: 1,
          name: "长江大桥",
          over: 251,
          alert: 121,
        },
        {
          id: 1,
          name: "长江大桥",
          over: 251,
          alert: 121,
        },
        {
          id: 1,
          name: "长江大桥",
          over: 251,
          alert: 121,
        },

      ],
    };
  },
  methods: {
    goSingle() {
      let routeData = this.$router.resolve({ path: "/single" });
      window.open(routeData.href, "_blank");
    },
    refresh() {},
    pageGet() {},
  },
};
</script>

